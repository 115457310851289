<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<style scoped>
div{
  width: 80%;
  margin: auto;
  border: 1px solid green;
}
</style>
